import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'tertiary'
  | 'lightColor'
  | 'darkColor'
  | 'oceanBlue'
  | 'sunsetOrange'
  | 'skyBlue'
  | 'mintGreen'
  | 'lavender'
  | 'grey';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// CUSTOM COLOR SCHEME
export const tertiary = {
  lighter: '#D0F4DE',
  light: '#A1E9C5',
  main: '#3DBEA3',
  dark: '#2B7B6F',
  darker: '#1E5549',
  contrastText: '#FFFFFF',
};

export const lightColor = {
  // Adjust the color values as needed
  lighter: '#F3F6F9',
  light: '#E1E9EF',
  main: '#CFD8E0',
  dark: '#A8B8C6',
  darker: '#7F8E9D',
  contrastText: '#000000',
};

export const darkColor = {
  // Adjust the color values as needed
  lighter: '#4A4A4A',
  light: '#333333',
  main: '#1C1C1C',
  dark: '#121212',
  darker: '#000000',
  contrastText: '#FFFFFF',
};

export const lavender = {
  lighter: '#E6CCFF',
  light: '#D1A3FF',
  main: '#B97AFF',
  dark: '#9252CC',
  darker: '#672E99',
  contrastText: '#FFFFFF',
};

export const mintGreen = {
  lighter: '#BCF8EC',
  light: '#8FEDD3',
  main: '#5AE1B5',
  dark: '#2C9578',
  darker: '#1D6B5A',
  contrastText: '#000000',
};

export const skyBlue = {
  lighter: '#D6EFFF',
  light: '#ADD8E6',
  main: '#87CEEB',
  dark: '#569BBF',
  darker: '#306D94',
  contrastText: '#000000',
};

export const sunsetOrange = {
  lighter: '#FFD1B2',
  light: '#FFA07A',
  main: '#FF7E40',
  dark: '#C64A00',
  darker: '#8A2E00',
  contrastText: '#FFFFFF',
};

export const oceanBlue = {
  lighter: '#A3D4F2',
  light: '#60B0E6',
  main: '#127FBF',
  dark: '#0B5A8C',
  darker: '#073659',
  contrastText: '#FFFFFF',
};

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const primary = {
  lighter: '#C8FAD6',
  light: '#5BE49B',
  main: '#00A76F',
  dark: '#007867',
  darker: '#004B50',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

export const warning = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  lightColor,
  darkColor,
  common,
  divider: alpha(grey[500], 0.2),
  action,
  tertiary,
  oceanBlue,
  sunsetOrange,
  skyBlue,
  mintGreen,
  lavender,
};

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
