// firebaseUtils.tsx
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';
import { ref, listAll, getStorage, uploadBytes, getMetadata, deleteObject, getDownloadURL, updateMetadata } from 'firebase/storage';
import { doc, query, where, limit, addDoc, getDoc, setDoc, getDocs, orderBy, Timestamp, deleteDoc, collection, documentId, getFirestore, doc as docGlob, runTransaction } from 'firebase/firestore';

import { FIREBASE_API } from 'src/config-global';

import { IKanban } from 'src/types/kanban';
import { IMealItem } from 'src/types/meals';
import { IOrderItem } from 'src/types/order';
import { IUserItem, IUserPublic } from 'src/types/user';
import { IEventItem, IMaterialSet, ITemplateItem, IActivityItem } from 'src/types/tour';




const firebaseApp = initializeApp(FIREBASE_API);
const dbGlob = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);


initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6LdyjiwpAAAAAPl6w3Ujze5nFyoU1reNY4aq6dY0'),
    isTokenAutoRefreshEnabled: true
});


/** INTERFACE AND INITILIZATION ** */

interface PhotoURL {
    path: string;
    preview: string;
}

interface UserProfileData {
    photoURL?: string | PhotoURL | File;
    displayName?: string;
    email?: string;
    phoneNumber?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    isPublic?: boolean;
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
    walktour?: boolean;
}


/** EXPORTED FUNCTIONS ** */

/** UTILITY ** */

export async function uploadFile(file: File, path: string, userId?: string) {
    const storage = getStorage();
    const fileRef = ref(storage, path);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
}

const removeUndefinedProperties = (obj: any) =>
    Object.entries(obj)
        .filter(([_, v]) => v !== undefined && !Number.isNaN(v))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

const deepCleanObject = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(deepCleanObject);
    } if (obj !== null && typeof obj === 'object') {
        return Object.entries(obj)
            .filter(([_, v]) => v !== undefined && !Number.isNaN(v))
            .reduce((acc, [k, v]) => ({ ...acc, [k]: deepCleanObject(v) }), {});
    }
    return obj;
};

export const refetchUserData = async () => {
    if (!auth.currentUser) {
        throw new Error('User is not logged in');
    }

    try {
        await auth.currentUser.reload();

        return auth.currentUser.toJSON();
    } catch (error) {
        console.error("Error refreshing user data:", error);
        throw error;
    }
}


/** PUBLIC ACCOUNT DATA */


export const fetchPublicAccountData = async (userId: string): Promise<IUserPublic | null> => {
    const docRef = docGlob(dbGlob, 'users', userId);

    // Specify the names of the fields you want to fetch from the array
    const fieldNames = ['displayName', 'photoURL', 'about', 'phoneNumber', 'email'];

    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();

            // Create an object with the specified fields
            const selectedData: IUserPublic = {
                id: userId,
                photoURL: userData.photoURL,
                displayName: userData.displayName,
                about: userData.about,
                phoneNumber: userData.phoneNumber,
                email: userData.email,
            };

            fieldNames.forEach(fieldName => {
                if (userData[fieldName] !== undefined) {
                    selectedData[fieldName as keyof IUserPublic] = userData[fieldName];
                }
            });


            return selectedData;
        }
        return null;

    } catch (error) {
        console.error("Error fetching public account data:", error);
        return null;
    }
};

export const fetchPublicEvents = (): string => "hello"



/** AUTHENTICATION ** */

export const getUserProfile = async (userId: string) => {
    const docRef = docGlob(dbGlob, 'users', userId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
};

export const updateUserProfile = async (userId: string, profileData: UserProfileData) => {
    const storage = getStorage();
    const updateData: UserProfileData = { ...profileData };

    if (profileData.photoURL && profileData.photoURL instanceof File) {
        const file = profileData.photoURL;
        const storageRef = ref(storage, `user-profiles/${userId}/${file.name}`);

        // Upload the file
        const snapshot = await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Update profileData with the download URL
        updateData.photoURL = downloadURL;
    }

    const cleanData = removeUndefinedProperties(updateData);

    const docRef = docGlob(dbGlob, 'users', userId);
    try {
        await setDoc(docRef, cleanData, { merge: true });
    } catch (error) {
        throw new Error('Error updating user profile');
    }


};

export const updatePassword = async (userId: string, oldPassword: string, newPassword: string) => {
    const docRef = docGlob(dbGlob, 'users', userId);

    // check if old Password is correct
    const docSnap = await getDoc(docRef);
    const user = docSnap.data();
    if (user?.password !== oldPassword) {
        throw new Error('Old password is incorrect');
    }

    await setDoc(docRef, { password: newPassword }, { merge: true });
};




/** EVENTS ** */
export const fetchEventsByUser = async (userId: string, orgId: string, eventId?: string, option?: string) => {
    const db = getFirestore();

    let events = [];

    if (eventId) {
        // Fetch a single event by ID, from either public or specific org collection
        const eventRef = (!orgId || orgId === '0') ? doc(db, 'publicEvents', eventId) : doc(db, `organizations/${orgId}/events`, eventId);
        const docSnapshot = await getDoc(eventRef);
        if (!docSnapshot.exists()) {
            throw new Error(`Event with ID ${eventId} not found`);
        }

        // Directly add the event data based on the 'slider' option
        events.push(option === 'slider' ?
            formatForSlider(docSnapshot.data()) :
            {
                ...docSnapshot.data(), id: docSnapshot.id, available: {
                    startDate: getValidDate(docSnapshot.data().available?.startDate),
                    endDate: getValidDate(docSnapshot.data().available?.endDate)
                },
            });
    } else {
        // Fetching all events based on orgId being specified or fetching publicly
        let eventsQuery;
        if (!orgId || orgId === '0') {
            eventsQuery = query(collection(db, 'publicEvents'));
        } else {
            eventsQuery = query(collection(db, `organizations/${orgId}/events`), where('archived', '==', false));
        }

        const querySnapshot = await getDocs(eventsQuery);
        events = querySnapshot.docs.map(docSnapshot => option === 'slider' ?
            formatForSlider(docSnapshot.data()) :
            {
                ...docSnapshot.data(), id: docSnapshot.id, available: {
                    startDate: getValidDate(docSnapshot.data().available?.startDate),
                    endDate: getValidDate(docSnapshot.data().available?.endDate)
                }
            }
        );
    }

    if (events.length <= 0) throw new Error('No events found');
    return events.length === 1 ? events[0] : events; // Return a single event or an array of events
};

function formatForSlider(eventData: any) {
    // Adjust this function based on what 'slider' option should return
    return {
        id: eventData.id,
        name: eventData.name,
        available: {
            startDate: getValidDate(eventData?.available?.startDate),
            endDate: getValidDate(eventData?.available?.endDate),
        },
        images: eventData.images,
    };
}

const getValidDate = (dateInput: any): Date => {
    if (dateInput?.toDate) {
        return dateInput.toDate();
    }
    const date = new Date(dateInput);
    return Number.isNaN(date.getTime()) ? new Date(0) : date; // Return epoch start as a fallback
};



export const fetchEventsByUserEmail = async (userEmail: string, orgId: string, eventId?: string): Promise<IEventItem[] | IEventItem> => {
    const db = getFirestore();


    const eventsCollectionRef = collection(db, 'organizations', orgId, 'events');

    let querySnapshot;

    if (eventId) {
        const eventRef = docGlob(eventsCollectionRef, eventId);
        const docSnapshot = await getDoc(eventRef);

        if (!docSnapshot.exists()) {
            throw new Error(`Event with ID ${eventId} not found`);
        }

        // Check if the user's email is in the access list or the event is public
        const eventData = docSnapshot.data();
        if (!eventData.accessList.includes(userEmail) && eventData.publish === false) {
            throw new Error(`Event with ID ${eventId} not found`);
        }

        querySnapshot = { docs: [docSnapshot] }; // Mimic the structure for consistency
    } else {
        if (!userEmail) {
            throw new Error('User email is required');
        }
        const q = query(eventsCollectionRef, where('accessListEmails', 'array-contains', userEmail));
        querySnapshot = await getDocs(q);
    }

    const events = querySnapshot.docs.map(localDoc => {
        const data = localDoc.data();
        const getValidDateLocal = (dateInput: any) => {
            // Check if dateInput is a Firebase Timestamp
            if (dateInput && typeof dateInput.toDate === 'function') {
                return dateInput.toDate();
            }

            // Handle as a regular date string or object
            const date = new Date(dateInput);
            return Number.isNaN(date.getTime()) ? new Date() : date;
        };

        const createdAt = getValidDateLocal(data.createdAt);
        const startDate = getValidDateLocal(data.available?.startDate);
        const endDate = getValidDateLocal(data.available?.endDate);

        return {
            id: localDoc.id,
            userId: data.userId,
            ...data,
            createdAt,
            available: {
                startDate,
                endDate,
            },
        } as IEventItem;
    });

    return eventId ? events[0] : events;
};

export const createOrUpdateEvent = async (eventData: IEventItem, orgId: string, organizerId: string, eventId?: string) => {
    const db = getFirestore();
    let eventDocRef;
    eventId = eventId || eventData.id || '';


    const cleanEventData = removeUndefinedProperties({
        ...eventData,
        updatedAt: new Date(),
        organizerId,
        createdAt: eventData.createdAt || new Date(),
    });

    const cleanPublicInfo = removeUndefinedProperties({
        name: eventData.name,
        content: eventData.content,
        images: eventData.images,
        available: {
            startDate: eventData.available?.startDate,
            endDate: eventData.available?.endDate,
        },
        tags: eventData.tags,
        publish: eventData.publish,
        updatedAt: new Date(),
    });


    const eventCollectionRef = collection(db, 'organizations', orgId, 'events');

    if (!eventId || eventId === '') {
        // Creating a new event
        eventDocRef = await addDoc(eventCollectionRef, cleanEventData);
        eventId = eventDocRef.id;
    } else {
        // Updating an existing event
        eventDocRef = doc(db, 'organizations', orgId, 'events', eventId);
        const docSnapshot = await getDoc(eventDocRef);
        if (!docSnapshot.exists()) throw new Error(`Event with ID ${eventId} not found`);

        await setDoc(eventDocRef, cleanEventData, { merge: true });
    }

    // Handle images separately to not overwrite existing ones if not provided
    if (eventData.images && eventData.images.length > 0) {
        await handleEventImages(eventData.images, orgId, eventId);
    }

    // Handle public event information
    const publicInfoRef = doc(db, 'publicEvents', eventId);
    if (eventData.publish) {
        await setDoc(publicInfoRef, cleanPublicInfo, { merge: true });
    } else {
        await deleteDoc(publicInfoRef);
    }

    return eventId;
};

async function handleEventImages(images: (File | string)[], orgId: string, eventId: string) {
    const db = getFirestore();
    const storagePath = `${orgId}/events/${eventId}/images`;
    const imageUrls = await Promise.all(images.map(image => {
        if (image instanceof File) {
            return uploadFile(image, `${storagePath}/${image.name}`);
        }
        return Promise.resolve(image); // Assume already uploaded images are passed as string URLs
    }));

    // Update the event with the new image URLs without overwriting other data
    const eventRef = doc(db, 'organizations', orgId, 'events', eventId);
    await setDoc(eventRef, { images: imageUrls }, { merge: true });
}

export const deleteEventById = async (eventId: string, orgId: string, userId: string) => {
    const db = getFirestore();

    // Adjust the path to reference the event in the subcollection under the user's organization
    const eventRef = doc(db, 'organizations', orgId, 'events', eventId);
    const docSnapshot = await getDoc(eventRef);

    if (!docSnapshot.exists()) {
        throw new Error(`Event with ID ${eventId} not found`);
    }

    // Assuming organizerId is still relevant in your new structure
    const event = docSnapshot.data();
    if (event?.userId !== userId) {
        throw new Error(`Event with ID ${eventId} not found`);
    }

    // Set 'archived' to true instead of deleting the document
    await setDoc(eventRef, { archived: true }, { merge: true });

    return true;
};


/** TEMPLATES ** */

export const createOrUpdateTemplate = async (templateData: ITemplateItem, orgId: string, organizerId: string, templateId?: string) => {
    const db = getFirestore();
    let newTemplateId = templateId || templateData.id || '';


    if (!newTemplateId) {

        // Add createdAt and updatedAt
        templateData.createdAt = new Date();
        templateData.updatedAt = new Date();
        const templatesCollectionRef = collection(db, 'organizations', orgId, 'templates');
        const cleanTemplateData = removeUndefinedProperties(templateData);
        const docRef = await addDoc(templatesCollectionRef, { ...cleanTemplateData, organizerId });
        newTemplateId = docRef.id;
    } else {
        templateData.updatedAt = new Date();
        const cleanTemplateData = removeUndefinedProperties(templateData);
        const templateRef = docGlob(db, 'organizations', orgId, 'templates', newTemplateId);
        await setDoc(templateRef, { ...cleanTemplateData, organizerId }, { merge: true });
    }

    return newTemplateId;
}

export const fetchTemplatesByUser = async (userId: string, orgId: string, templateId?: string): Promise<ITemplateItem[]> => {

    const templatesCollectionRef = collection(dbGlob, 'organizations', orgId, 'templates');
    const queryConstraints = [];


    if (templateId) {
        queryConstraints.push(where(documentId(), '==', templateId));
    }

    if (userId) {
        queryConstraints.push(where('organizerId', '==', userId));
    }

    queryConstraints.push(where('archived', '==', false));
    const q = query(templatesCollectionRef, ...queryConstraints);

    const querySnapshot = await getDocs(q);

    const templates = querySnapshot.docs.map(inncerDoc => ({
        ...inncerDoc.data(),
        name: inncerDoc.data().name,
        id: inncerDoc.id,
        userId: inncerDoc.data().userId || userId,
    }));

    return templates;
};

export const deleteTemplateById = async (templateId: string, orgId: string, userId: string) => {

    const db = getFirestore();


    const templateRef = docGlob(db, 'organizations', orgId, 'templates', templateId);
    const docSnapshot = await getDoc(templateRef);
    if (!docSnapshot.exists()) {
        throw new Error(`Template with ID ${templateId} not found`);
    }
    const template = docSnapshot.data();
    if (template?.organizerId !== userId) {
        throw new Error(`Template with ID ${templateId} not found`);
    }
    await setDoc(templateRef, { archived: true }, { merge: true });

    return true;
};

/** MEALS */


export const deleteMealById = async (mealId: string, orgId: string, userId: string) => {

    const db = getFirestore();
    const mealRef = docGlob(db, 'organizations', orgId, 'meals', mealId);
    const docSnapshot = await getDoc(mealRef);
    if (!docSnapshot.exists()) {
        throw new Error(`Meal with ID ${mealId} not found`);
    }
    const meal = docSnapshot.data();
    if (meal?.userId !== userId) {
        throw new Error(`Meal with ID ${mealId} not found`);
    }
    await setDoc(mealRef, { archived: true }, { merge: true });

    const publicInfoRef = doc(db, 'publicMeals', mealId);
    await deleteDoc(publicInfoRef);

    return true;
};

export const createOrUpdateMeal = async (mealData: IMealItem, orgId: string, userId: string) => {
    const db = getFirestore();

    // Preparing public information for duplication
    const publicInfo = {
        name: mealData.name,
        description: mealData.description,
        prepTime: mealData.prepTime,
        images: mealData.images,
        ingredients: mealData.ingredients,
        nutritionalInfo: mealData.nutritionalInfo,
        cuisines: mealData.cuisines,
        type: mealData.type,
        mealTimes: mealData.mealTimes,
        baseServingSize: mealData.baseServingSize,
        preparationSteps: mealData.preparationSteps,
        tags: mealData.tags,
        createdAt: mealData.createdAt || new Date(),
        updatedAt: new Date(),
        price: mealData.price,
        ratings: mealData.ratings,
        dietaryRestrictions: mealData.dietaryRestrictions,
        maxServingSize: mealData.maxServingSize,
    };

    // Cleaning up the data
    const cleanPublicInfo = removeUndefinedProperties(publicInfo);
    const cleanMealData = removeUndefinedProperties(mealData);

    let mealDocRef;

    if (!mealData.id) {
        // Creating a new meal
        const mealsCollectionRef = collection(db, 'organizations', orgId, 'meals');
        mealDocRef = await addDoc(mealsCollectionRef, { ...cleanMealData, userId, createdAt: new Date(), updatedAt: new Date() });
    } else {
        // Updating an existing meal
        mealDocRef = doc(db, 'organizations', orgId, 'meals', mealData.id);
        await setDoc(mealDocRef, { ...cleanMealData, updatedAt: new Date() }, { merge: true });
    }

    // Handle public meal information
    const publicInfoRef = doc(db, 'publicMeals', mealDocRef.id);
    if (mealData.publish) {
        await setDoc(publicInfoRef, cleanPublicInfo, { merge: true });
    } else {
        await deleteDoc(publicInfoRef);
    }

    // Return the meal ID
    return mealDocRef.id;
};

export const fetchMealsByUser = async (userId: string, orgId: string, mealId?: string, simple = false) => {
    const db = getFirestore();

    let meals = [];

    if (mealId) {
        // Determine the correct document reference based on orgId being defined or not
        const mealRef = !orgId || orgId === '0' ? doc(db, 'publicMeals', mealId) : doc(db, `organizations/${orgId}/meals`, mealId);
        const docSnapshot = await getDoc(mealRef);
        if (docSnapshot.exists()) {
            // Directly push the meal data into the meals array, considering the simple flag
            meals.push(simple ? { id: docSnapshot.id, name: docSnapshot.data().name } : docSnapshot.data());
        }
    } else {
        // Fetching all meals based on orgId being specified or fetching publicly
        let mealsQuery;
        if (!orgId || orgId === '0') {
            mealsQuery = query(collection(db, 'publicMeals'));
        } else {
            mealsQuery = query(collection(db, `organizations/${orgId}/meals`));
        }

        const querySnapshot = await getDocs(mealsQuery);
        meals = querySnapshot.docs.map(docSnapshot =>
            simple ? { id: docSnapshot.id, name: docSnapshot.data().name } : { id: docSnapshot.id, ...docSnapshot.data() }
        );
    }

    if (meals && meals.length <= 0) throw new Error('No meals found');
    return meals;


};



/** ACTIVITIES */

export const fetchActivitiesByUser = async (userId: string, orgId: string, activityId?: string, simple?: boolean) => {
    const db = getFirestore();
    let activities = [];

    if (activityId) {
        const activityRef = !orgId || orgId === '0' ? doc(db, 'publicActivities', activityId) : doc(db, `organizations/${orgId}/activities`, activityId);
        const docSnapshot = await getDoc(activityRef);
        if (docSnapshot.exists()) {
            activities.push(simple ? { id: docSnapshot.id, name: docSnapshot.data().name } : docSnapshot.data());
        }
    } else {
        let activitiesQuery;
        if (!orgId || orgId === '0') {
            activitiesQuery = query(collection(db, 'publicActivities'));
        } else {
            activitiesQuery = query(collection(db, `organizations/${orgId}/activities`));
        }

        const querySnapshot = await getDocs(activitiesQuery);
        activities = querySnapshot.docs.map(docSnapshot =>
            simple ? { id: docSnapshot.id, name: docSnapshot.data().name } : { id: docSnapshot.id, ...docSnapshot.data() }
        );
    }

    if (activities.length <= 0) throw new Error('No activities found');
    return activities;
};

export const createOrUpdateActivity = async (activityData: IActivityItem, orgId: string, userId: string) => {
    const db = getFirestore();

    const PublicInfo = {
        name: activityData.name,
        description: activityData.description,
        variations: activityData.variations,
        tipps: activityData.tipps,
        ageRange: activityData.ageRange,
        category: activityData.category,
        difficulty: activityData.difficulty,
        timeMinutes: activityData.timeMinutes,
        images: activityData.images,
        materials: activityData.materials,
        tags: activityData.tags,
        updatedAt: new Date(),
        createdAt: activityData.createdAt || new Date(),
    };

    const cleanPublicInfo = removeUndefinedProperties(PublicInfo);
    const cleanActivityData = removeUndefinedProperties(activityData);


    let activityDocRef;

    if (!activityData.id) {
        const activitiesCollectionRef = collection(db, 'organizations', orgId, 'activities');
        activityDocRef = await addDoc(activitiesCollectionRef, { ...cleanActivityData, userId, createdAt: new Date(), updatedAt: new Date() });
    } else {
        activityDocRef = doc(db, 'organizations', orgId, 'activities', activityData.id);
        await setDoc(activityDocRef, { ...activityData, userId, updatedAt: new Date() }, { merge: true });
    }

    if (activityData.publish) {
        const publicInfoRef = doc(db, 'publicActivities', activityDocRef.id);
        await setDoc(publicInfoRef, { ...cleanPublicInfo, updatedAt: new Date() }, { merge: true });
    } else {
        const publicInfoRef = doc(db, 'publicActivities', activityDocRef.id);
        await deleteDoc(publicInfoRef);
    }

    return activityDocRef.id;
};

export const deleteActivityById = async (activityId: string, orgId: string, userId: string) => {
    const db = getFirestore();
    const activityRef = doc(db, 'organizations', orgId, 'activities', activityId);
    const docSnapshot = await getDoc(activityRef);
    if (!docSnapshot.exists()) {
        throw new Error(`Activity with ID ${activityId} not found`);
    }
    const activity = docSnapshot.data();
    if (activity?.userId !== userId) {
        throw new Error(`Activity with ID ${activityId} not found`);
    }
    await setDoc(activityRef, { archived: true }, { merge: true });

    const publicInfoRef = doc(db, 'publicActivities', activityId);
    await deleteDoc(publicInfoRef);

    return true;
}




/* export const fetchActivitiesByUser = async (userId: string, orgId: string, activityId?: string, values?: string[]): Promise<IActivityItem | IActivityItem[]> => {
    const db = getFirestore();
 
    const activityCollectionRef = collection(db, 'organizations', orgId, 'Activities');
    let q;
 
    if (activityId) {
        // Fetch a single meal by ID
        const activityRef = docGlob(activityCollectionRef, activityId);
        const docSnapshot = await getDoc(activityRef);
 
        if (!docSnapshot.exists()) {
            throw new Error(`Activity with ID ${activityId} not found`);
        }
        if (docSnapshot.data().userId !== userId && docSnapshot.data().publish === false) {
            throw new Error(`Activity with ID ${activityId} not found`);
        }
 
 
        return {
            id: docSnapshot.id,
            ...(docSnapshot.data() as Omit<IActivityItem, 'id'>),
            createdAt: docSnapshot.data().createdAt?.toDate() ?? new Date(),
 
        } as IActivityItem;
    } if (values && values.length > 0) {
        // Fetch multiple activities by an array of IDs
        const localQuery = query(activityCollectionRef, where(documentId(), 'in', values));
        const querySnapshot = await getDocs(localQuery);
 
        if (querySnapshot.empty) {
            return [];
        }
 
        return querySnapshot.docs.map(localDoc => ({
            id: localDoc.id,
            ...localDoc.data(),
            createdAt: localDoc.data().createdAt?.toDate() ?? new Date(),
        })) as IActivityItem[];
    }
    if (userId === '0') {
        q = query(activityCollectionRef, where('archived', '==', false), where('publish', '==', true));
    } else {
        q = query(activityCollectionRef, where('userId', '==', userId), where('archived', '==', false));
    }
 
    const querySnapshot = await getDocs(q);
 
    if (querySnapshot.empty) {
        return [];
    }
 
    return querySnapshot.docs.map(inncerDoc => ({
        id: inncerDoc.id,
        ...inncerDoc.data(),
        createdAt: inncerDoc.data().createdAt?.toDate() ?? new Date(),
    })) as IActivityItem[];
 
 
};
 
export const createOrUpdateActivity = async (activityData: IActivityItem, orgId: string, userId: string) => {
    const db = getFirestore();
 
 
 
 
    let newActivityId = activityData.id || undefined;
 
    if (newActivityId === undefined) {
        activityData.createdAt = new Date();
 
        const cleanActivityData = removeUndefinedProperties(activityData);
        const activitiesCollectionRef = collection(db, 'organizations', orgId, 'Activities');
        const docRef = await addDoc(activitiesCollectionRef, { ...cleanActivityData, userId });
        newActivityId = docRef.id;
        await setDoc(docRef, { id: newActivityId }, { merge: true });
    } else {
        // Update existing meal
        const cleanActivityData = removeUndefinedProperties(activityData);
        const activityRef = docGlob(db, 'organizations', orgId, 'Activities', newActivityId);
        await setDoc(activityRef, { ...cleanActivityData, userId }, { merge: true });
    }
 
    return newActivityId; // Return the meal ID
};
 
export const deleteActivityById = async (activityId: string, orgId: string, userId: string) => {
 
    const db = getFirestore();
 
 
 
    const activityRef = docGlob(db, 'organizations', orgId, 'Activities', activityId);
    const docSnapshot = await getDoc(activityRef);
    if (!docSnapshot.exists()) {
        throw new Error(`Activity with ID ${activityId} not found`);
    }
    const activity = docSnapshot.data();
    if (activity?.userId !== userId) {
        throw new Error(`Activity with ID ${activityId} not found`);
    }
    await setDoc(activityRef, { archived: true }, { merge: true });
 
    return true;
}; */

/** PERSONAL STORAGE */

export const fetchFilesandFolders = async (userId: string) => {
    const storage = getStorage();

    const storageRef = ref(storage, `user-profiles/${userId}/personalStorage`);

    try {
        const result = await listAll(storageRef);
        // Process 'result.items' (files) and 'result.prefixes' (folders)
        return result;
    } catch (error) {
        console.error("Error fetching files: ", error);
        return null;
    }
}

export const uploadFilesToPersonalStorage = async (userId: string, files: File[]) => {
    const storage = getStorage();
    const uploadPath = `user-profiles/${userId}/personalStorage/`;

    const uploadPromises = files.map(file => {
        const fileRef = ref(storage, `${uploadPath}${file.name}`);
        return uploadBytes(fileRef, file)
            .then(() => getDownloadURL(fileRef));
    });

    try {
        const downloadURLs = await Promise.all(uploadPromises);
        return downloadURLs;
    } catch (error) {
        console.error('Error uploading files:', error);
        throw error;
    }
};

export const updateFileFromPersonalStorage = async (userId: string, storagePath: string, metaData: any) => {
    const customMetaData = {
        customMetadata: {
            ...Object.fromEntries(Object.entries(metaData).map(([key, value]) =>
                // Convert all values to string
                [key, typeof value === 'string' ? value : JSON.stringify(value)]
            ))
        }
    };

    try {
        const storageRef = ref(getStorage(), `user-profiles/${userId}/personalStorage/${storagePath}`);

        const { currentUser } = auth;
        if (!currentUser) {
            throw new Error("Unauthorized access. You are not logged in.");
        }

        if (currentUser.uid !== userId) {
            throw new Error("Unauthorized access. You are not the owner of this file.");
        }

        await updateMetadata(storageRef, customMetaData);

        return true;
    } catch (error) {
        console.error('Error updating file:', error);
        throw error;
    }
}

export const getMetaDataFromPersonalStorage = async (userId: string, storagePath: string) => {
    try {
        const storageRef = ref(getStorage(), `user-profiles/${userId}/personalStorage/${storagePath}`);

        const { currentUser } = auth;
        if (!currentUser) {
            throw new Error("Unauthorized access. You are not logged in.");
        }

        if (currentUser.uid !== userId) {
            throw new Error("Unauthorized access. You are not the owner of this file.");
        }

        const metadata = await getMetadata(storageRef);
        return metadata;
    } catch (error) {
        console.error('Error updating file:', error);
        throw error;
    }
}

export const getFileDownloadURL = async (filePath: string, userId: string): Promise<string> => {
    const storage = getStorage();
    const fileRef = ref(storage, filePath);

    try {
        const { currentUser } = auth;
        if (!currentUser) {
            throw new Error("Unauthorized access. You are not logged in.");
        }

        if (currentUser.uid !== userId) {
            throw new Error("Unauthorized access. You are not the owner of this file.");
        }

        const url = await getDownloadURL(fileRef);
        return url;
    } catch (error) {
        console.error('Error getting download URL:', error);
        throw error;
    }
};

export const deleteFileFromPersonalStorage = async (userId: string, storagePath: string) => {
    try {
        const storageRef = ref(getStorage(), `user-profiles/${userId}/personalStorage/${storagePath}`);

        const { currentUser } = auth;
        if (!currentUser) {
            throw new Error("Unauthorized access. You are not logged in.");
        }

        if (currentUser.uid !== userId) {
            throw new Error("Unauthorized access. You are not the owner of this file.");
        }

        await deleteObject(storageRef);
        return true;
    } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
    }
}

/** PEOPLE */

export const fetchPeopleByUser = async (userId: string, orgId: string, personIds?: string | string[], status?: string): Promise<IUserItem | IUserItem[]> => {
    const db = getFirestore();

    const peopleCollectionRef = collection(db, 'organizations', orgId, 'people');

    if (typeof personIds === 'string') {
        const personRef = doc(peopleCollectionRef, personIds);
        const docSnapshot = await getDoc(personRef);

        if (!docSnapshot.exists()) {
            throw new Error(`Person with ID ${personIds} not found`);
        }

        const personData = docSnapshot.data() as IUserItem;
        return { ...personData, id: docSnapshot.id };
    }

    // Fetch multiple documents by IDs
    if (Array.isArray(personIds) && personIds.length > 0) {
        const personDocuments = await Promise.all(
            personIds.map(id => getDoc(doc(peopleCollectionRef, id)))
        );

        return personDocuments
            .filter(docSnapshot => docSnapshot.exists())
            .map(docSnapshot => ({
                ...(docSnapshot.data() as IUserItem),
                id: docSnapshot.id,
            }));
    }

    // Fallback query if no personIds provided
    const queryConstraints = [where('userId', '==', userId), where('archived', '==', false)];
    if (status) {
        queryConstraints.push(where('role', '==', status));
    }

    const q = query(peopleCollectionRef, ...queryConstraints);
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        return [];
    }

    return querySnapshot.docs.map(docSnapshot => ({
        ...(docSnapshot.data() as IUserItem),
        id: docSnapshot.id,
    }));
};

export const createOrUpdatePerson = async (personData: IUserItem, orgId: string, userId: string) => {
    const db = getFirestore();

    const userEmail = personData.email.toLowerCase();

    const personRef = doc(db, `organizations/${orgId}/people/${userEmail}`);

    const docSnapshot = await getDoc(personRef);


    if (!docSnapshot.exists()) {

        const cleanPersonData = removeUndefinedProperties(personData);
        await setDoc(personRef, { ...cleanPersonData, userId });

    } else {
        const cleanPersonData = removeUndefinedProperties(personData);
        // throw a browser confirm dialog to ask the user if they want to update the person
        const confirmUpdate = window.confirm('This person already exists or was deleted before. Do you want to update the person?');
        if (confirmUpdate) {
            await setDoc(personRef, { ...cleanPersonData, userId }, { merge: true });
        } else {
            return null;
        }
    }

    return true;
}

export const deletePersonById = async (personId: string, orgId: string, userId: string) => {

    // Throw error if user is not logged in
    if (!auth.currentUser) {
        throw new Error('User is not logged in');
    }

    const db = getFirestore();

    const personRef = docGlob(db, 'organizations', orgId, 'people', personId);
    // check if user is the owner of the person
    if (auth.currentUser.uid !== userId) {
        throw new Error('User is not the owner of this person');
    }

    const docSnapshot = await getDoc(personRef);
    if (!docSnapshot.exists()) {
        throw new Error(`Person with ID ${personId} not found`);
    }
    const person = docSnapshot.data();
    if (person?.userId !== userId) {
        throw new Error(`Person with ID ${personId} not found`);
    }
    await setDoc(personRef, { archived: true }, { merge: true });

    return true;
}


/** KANABN */

export const fetchBoardByUser = async (userId: string, orgId: string, boardId?: string): Promise<IKanban | null> => {
    const db = getFirestore();
    const boardsCollectionRef = collection(db, 'organizations', orgId, 'KanbanBoards');

    if (boardId) {
        const boardRef = doc(boardsCollectionRef, boardId);
        const docSnapshot = await getDoc(boardRef);

        if (docSnapshot.exists()) {
            return { ...docSnapshot.data() as IKanban, id: docSnapshot.id };
        }
        return null;

    }
    const q = query(boardsCollectionRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        // Assuming you want the first board if there are multiple
        const firstDoc = querySnapshot.docs[0];
        return { ...firstDoc.data() as IKanban, id: firstDoc.id };
    }
    return null;

};


export const updateKanbanBoard = async (boardId: string, orgId: string, boardData: IKanban, userId: string) => {
    if (!boardId || !userId) {
        throw new Error("Invalid input parameters.");
    }

    const db = getFirestore();

    const boardRef = doc(db, 'organizations', orgId, 'KanbanBoards', boardId);

    await runTransaction(db, async (transaction) => {
        const docSnap = await transaction.get(boardRef);

        boardData.updatedAt = new Date(); // Set updated date for both update and create

        if (docSnap.exists()) {
            const existingData = docSnap.data();
            if (existingData.userId !== userId) {
                throw new Error("Unauthorized: You do not have permission to update this board.");
            }

            // Additional permission checks for update here if necessary

            transaction.update(boardRef, boardData);
        } else {
            // For new board creation, set the createdAt date
            boardData.createdAt = new Date();

            // If it's a new board, userId should be set from the input parameter
            boardData.userId = userId;

            transaction.set(boardRef, boardData);
        }
    });
};



/* const transformBoardData = (rawData: any) => {
 
    const transformedTasks: Record<string, IKanbanTask> = {};
 
    rawData.tasks.forEach((task: IKanbanTask) => {
 
        const due: [Date | null, Date | null] = [
            task.due && task.due[0] ? new Date(task.due[0]) : null ?? null,
            task.due && task.due[1] ? new Date(task.due[1]) : null
        ];
 
        transformedTasks[task.id] = {
            ...task,
            due,
            comments: task.comments?.map((comment: any) => ({
                ...comment,
                createdAt: new Date(comment.createdAt)
            })),
            assignee: task.assignee?.map((assign: any) => ({
                ...assign,
                lastActivity: new Date(assign.lastActivity)
            }))
        };
    });
 
    const transformedColumns: Record<string, IKanbanColumn> = {};
    rawData.columns.forEach((column: IKanbanColumn) => {
        transformedColumns[column.id] = {
            ...column
        };
    });
 
    return {
        tasks: transformedTasks,
        columns: transformedColumns,
        ordered: rawData.ordered
    };
}; */

/** TASKS */


export const fetchTasksByUser = async (userId: string, orgId: string, taskId?: string) => {
    const db = getFirestore();



    const tasksCollectionRef = collection(db, 'organizations', orgId, 'tasks');
    let querySnapshot;

    if (taskId) {
        const taskRef = docGlob(tasksCollectionRef, taskId);
        const docSnapshot = await getDoc(taskRef);
        if (!docSnapshot.exists()) {
            throw new Error(`Task with ID ${taskId} not found`);
        }

        // if user is not the owner of the task
        if (docSnapshot.data().userId !== userId) {
            throw new Error(`Task with ID ${taskId} not found`);
        }

        querySnapshot = { docs: [docSnapshot] };
    } else {
        const q = query(tasksCollectionRef,
            where('userId', '==', userId),
            where('archived', '==', false),);
        querySnapshot = await getDocs(q);
    }

    const tasks = await Promise.all(querySnapshot.docs.map(async innerDoc => {
        const data = innerDoc.data();
        let eventName = '';

        // Check if the task has an eventId and fetch the event's name
        if (data.eventId) {
            try {
                const event = await fetchEventsByUser('0', data.eventId, 'slider');
                if ('title' in event) {
                    eventName = event.title as string;
                }
            } catch (error) {
                console.error(`Failed to fetch event: ${error}`);
            }
        }

        return {
            id: innerDoc.id,
            ...data,
            eventName,
            due: data.due ? new Date(data.due.seconds * 1000) : null,
        };
    }));

    return taskId ? tasks[0] : tasks;
}

export const createOrUpdateTask = async (taskData: any, orgId: string, userId: string,) => {
    const db = getFirestore();


    let newTaskId = taskData.id || undefined;

    if (newTaskId === undefined) {
        taskData.createdAt = new Date();

        const cleanTaskData = removeUndefinedProperties(taskData);
        const tasksCollectionRef = collection(db, 'organizations', orgId, 'tasks');
        const docRef = await addDoc(tasksCollectionRef, { ...cleanTaskData, userId });
        newTaskId = docRef.id;
        await setDoc(docRef, { id: newTaskId }, { merge: true });
    } else {
        const cleanTaskData = removeUndefinedProperties(taskData);
        const taskRef = docGlob(db, 'organizations', orgId, 'tasks', newTaskId);
        await setDoc(taskRef, { ...cleanTaskData, userId }, { merge: true });
    }

    return newTaskId; // Return the meal ID
}

export const deleteTaskById = async (taskId: string, orgId: string, userId: string) => {

    // Throw error if user is not logged in
    if (!auth.currentUser) {
        throw new Error('User is not logged in');
    }

    const db = getFirestore();


    const taskRef = docGlob(db, 'organizations', orgId, 'tasks', taskId);
    // check if user is the owner of the task
    if (auth.currentUser.uid !== userId) {
        throw new Error('User is not the owner of this task');
    }

    const docSnapshot = await getDoc(taskRef);
    if (!docSnapshot.exists()) {
        throw new Error(`Task with ID ${taskId} not found`);
    }
    const task = docSnapshot.data();
    if (task?.userId !== userId) {
        throw new Error(`Task with ID ${taskId} not found`);
    }
    await setDoc(taskRef, { archived: true }, { merge: true });

    return true;
}

/** ORDER */

export const createOrUpdateOrder = async (orderData: IOrderItem, userId?: string) => {
    const db = getFirestore();

    let newOrderId = orderData.id || undefined;

    if (!userId) {
        userId = 'guest';
    }

    if (newOrderId === undefined) {
        orderData.createdAt = new Date();
        const cleanOrderData = deepCleanObject(orderData);
        const ordersCollectionRef = collection(db, 'orders');
        const createdAt = new Date();
        const docRef = await addDoc(ordersCollectionRef, { ...cleanOrderData, userId, createdAt });
        newOrderId = docRef.id;
        await setDoc(docRef, { id: newOrderId }, { merge: true });
    } else {
        const cleanOrderData = deepCleanObject(orderData);
        const orderRef = docGlob(db, 'orders', newOrderId);
        const updatedAt = new Date();
        await setDoc(orderRef, { ...cleanOrderData, userId, updatedAt }, { merge: true });
    }

    return newOrderId;
}

export const fetchOrdersByUser = async (userId: string, orderId?: string): Promise<IOrderItem | IOrderItem[]> => {
    const db = getFirestore();
    const ordersCollectionRef = collection(db, 'orders');

    if (orderId) {
        // Fetch a specific order by orderId
        const orderRef = doc(ordersCollectionRef, orderId);
        const docSnapshot = await getDoc(orderRef);

        if (docSnapshot.exists() && docSnapshot.data().userId === userId) {
            return { id: docSnapshot.id, ...docSnapshot.data() } as IOrderItem;
        }
        throw new Error("Order not found or user does not have permission to access this order.");

    } else {
        // Fetch all orders for the given userId
        const q = query(ordersCollectionRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        const orders = querySnapshot.docs.map(localDoc => ({ id: localDoc.id, ...localDoc.data() }) as IOrderItem);
        return orders;
    }
};


/** MAIL */

export const sendEmail = async (emailData: { to: string; subject: string; text: string, html: string }, orgId: string,) => {
    const db = getFirestore();
    const authLocal = getAuth();

    if (!authLocal.currentUser) {
        throw new Error('User not authenticated');
    }

    const userId = authLocal.currentUser.uid;
    const userEmailTimestampsRef = doc(db, 'organizations', orgId, 'emailTimestamps', userId);
    const maxEmailsPerHour = 60;

    try {
        // Check the email count in the last hour
        const timestampDoc = await getDoc(userEmailTimestampsRef);
        let timestamps = [];
        if (timestampDoc.exists() && timestampDoc.data().timestamps) {
            timestamps = timestampDoc.data().timestamps.filter((timestamp: any) =>
                Date.now() - timestamp.toMillis() < 3600000
            );
        }

        if (timestamps.length >= maxEmailsPerHour) {
            throw new Error('Email limit reached. Please wait before sending another email.');
        }

        // Send the email
        const emailRef = collection(db, 'mail');
        await addDoc(emailRef, {
            userId,
            to: emailData.to,
            message: {
                subject: emailData.subject,
                text: emailData.text,
                html: emailData.html,
            },
            // Other necessary data
        });

        const currentTime = new Date();

        // Convert the Date object to a Firestore timestamp
        const firestoreTimestamp = Timestamp.fromDate(currentTime);

        // Update the timestamps array
        timestamps.push(firestoreTimestamp); await setDoc(userEmailTimestampsRef, { timestamps });

        return 'Email queued for sending';
    } catch (error) {
        throw new Error(`Failed to send email: ${error}`);
    }
};

/** COMPONENTS */

export const fetchComponentsByUser = async (userId: string, orgId: string, componentIds?: string[]) => {

    const authLocal = getAuth();
    const user = authLocal.currentUser;
    if (!user || user.uid !== userId) {
        throw new Error("Unauthorized access");
    }

    if (!userId || !orgId || !componentIds) {
        throw new Error("Invalid parameters");
    }


    const db = getFirestore();
    const componentsCollectionRef = collection(db, 'organizations', orgId, 'components');

    if (componentIds && componentIds.length) {
        const fetchPromises = componentIds.map(async (componentId) => {
            const componentRef = doc(componentsCollectionRef, componentId);
            const docSnapshot = await getDoc(componentRef);
            if (docSnapshot.exists()) {
                return { id: docSnapshot.id, ...docSnapshot.data() };
            }
            // Decide how to handle missing components: throw error, return null, etc.
            console.error(`Component with ID ${componentId} not found`);
            return null; // or handle differently

        });
        return Promise.all(fetchPromises);
    }

    const docSnapshot = await getDocs(componentsCollectionRef);

    if (docSnapshot.empty) {
        throw new Error(`Components not found`);

    } else {
        return docSnapshot.docs.map(docLocal => ({ id: docLocal.id, ...docLocal.data() }));
    }

};

export const createOrUpdateComponent = async (componentData: IMaterialSet, orgId: string, userId: string, componentId?: string) => {

    const authLocal = getAuth();
    const user = authLocal.currentUser;
    if (!user || user.uid !== userId) {
        throw new Error("Unauthorized access");
    }

    const db = getFirestore();

    if (!userId || !orgId || !componentData) {
        throw new Error("User ID is required");
    }

    let newComponentId = componentId || componentData.id || '';

    if (!newComponentId) {
        componentData.createdAt = new Date();
        const cleanComponentData = removeUndefinedProperties(componentData);
        const docRef = await addDoc(collection(db, 'organizations', orgId, 'components'), { ...cleanComponentData, userId });
        newComponentId = docRef.id;
    } else {
        componentData.updatedAt = new Date();
        const cleanComponentData = removeUndefinedProperties(componentData);
        const componentRef = doc(db, 'organizations', orgId, 'components', newComponentId);
        await setDoc(componentRef, { ...cleanComponentData, userId }, { merge: true });
    }

    return newComponentId;
}

export const deleteComponentById = async (componentId: string, orgId: string, userId: string) => {
    const authLocal = getAuth();
    const user = authLocal.currentUser;
    if (!user || user.uid !== userId) {
        throw new Error("Unauthorized access");
    }

    const db = getFirestore();



    const componentRef = doc(db, 'organizations', orgId, 'components', componentId);
    const docSnapshot = await getDoc(componentRef);
    if (!docSnapshot.exists()) {
        throw new Error(`Component with ID ${componentId} not found`);
    }
    const component = docSnapshot.data();
    if (component?.userId !== userId) {
        throw new Error(`Component with ID ${componentId} not found`);
    }
    await setDoc(componentRef, { archived: true }, { merge: true });

    return true;
}

/** DUPLICATE ANY ELEMENT */

export const duplicateElement = async (elementId: string, orgId: string, userId: string, elementType: string, elementData?: any) => {
    const authLocal = getAuth();
    const user = authLocal.currentUser;
    if (!user || user.uid !== userId) {
        throw new Error("Unauthorized access");
    }

    let element = elementData;

    if (!elementData) {

        const db = getFirestore();

        let elementRef;
        switch (elementType) {
            case 'meal':
                elementRef = doc(db, 'organizations', orgId, 'meals', elementId);
                break;
            case 'activity':
                elementRef = doc(db, 'organizations', orgId, 'activities', elementId);
                break;
            case 'template':
                elementRef = doc(db, 'organizations', orgId, 'templates', elementId);
                break;
            case 'component':
                elementRef = doc(db, 'organizations', orgId, 'components', elementId);
                break;
            case 'invoices':
                elementRef = doc(db, 'organizations', orgId, 'invoices', elementId);
                break;
            default:
                throw new Error("Invalid element type");
        }

        const docSnapshot = await getDoc(elementRef);
        if (!docSnapshot.exists()) {
            throw new Error(`Element with ID ${elementId} not found`);
        }
        element = docSnapshot.data();

        if (element?.userId !== userId) {
            throw new Error(`Element with ID ${elementId} not found`);
        }
    }

    delete element.id;

    if (element.name) {
        element.name = `${element.name} (Copy)`;
    }

    if (element.title) {
        element.title = `${element.title} (Copy)`;
    }

    const newElementId = await createOrUpdateElement(element, orgId, userId, elementType);


    return newElementId;
}

export const createOrUpdateElement = async (elementData: any, orgId: string, userId: string, elementType: string) => {
    const db = getFirestore();

    // Check if the operation is meant for the organization itself
    if (elementType === 'organizations') {
        let newElementId = orgId; // For organizations, the orgId is the elementId
        if (!newElementId) {
            elementData.createdAt = new Date();
            const cleanElementData = removeUndefinedProperties(elementData);
            const docRef = await addDoc(collection(db, elementType), { ...cleanElementData, userId });
            newElementId = docRef.id;
        } else {
            elementData.updatedAt = new Date();
            const cleanElementData = removeUndefinedProperties(elementData);
            const orgRef = doc(db, elementType, newElementId);
            await setDoc(orgRef, { ...cleanElementData, userId }, { merge: true });
        }
        return newElementId;
    }
    // The original functionality for elements within an organization
    let newElementId = elementData.id || undefined;
    if (newElementId === undefined) {
        // Creating a new element within an organization
        elementData.createdAt = new Date();
        const cleanElementData = removeUndefinedProperties(elementData);
        const docRef = await addDoc(collection(db, 'organizations', orgId, elementType), { ...cleanElementData, userId });
        newElementId = docRef.id;
    } else {
        // Updating an existing element within an organization
        elementData.updatedAt = new Date();
        const cleanElementData = removeUndefinedProperties(elementData);
        const elementRef = doc(db, 'organizations', orgId, elementType, newElementId);
        await setDoc(elementRef, { ...cleanElementData, userId }, { merge: true });
    }
    return newElementId;

};


export const fetchElementById = async (elementIds: string[], orgId: string, userId: string, elementType: string) => {
    const db = getFirestore();

    if (elementType === 'organizations' && elementIds && elementIds.length) {
        const fetchPromises = elementIds.map(async (elementId) => {
            const elementRef = doc(db, elementType, elementId);
            const docSnapshot = await getDoc(elementRef);
            if (docSnapshot.exists()) {
                return { id: docSnapshot.id, ...docSnapshot.data() };
            }
            throw new Error(`Element with ID ${elementId} not found`);

        });
        return Promise.all(fetchPromises);
    }



    const baseCollectionPath = orgId === "0" || orgId === undefined ? 'publicElements' : 'organizations';
    /*     const collectionPath = orgId === "0" || orgId === undefined ? `public${elementType.charAt(0).toUpperCase() + elementType.slice(1)}` : elementType;
     */
    const collectionPath = elementType;
    const elementCollectionRef = collection(db, baseCollectionPath, orgId || '', collectionPath);
    const queryConstraints = [where('archived', '==', false)];

    if (!userId) {
        throw new Error("User ID is required");
    }

    if (elementIds && elementIds.length) {
        const fetchPromises = elementIds.map(async (elementId) => {
            const elementRef = doc(elementCollectionRef, elementId);

            const docSnapshot = await getDoc(elementRef);
            if (docSnapshot.exists()) {
                return { id: docSnapshot.id, ...docSnapshot.data() };
            }
            // Decide how to handle missing elements: throw error, return null, etc.
            console.error(`Element with ID ${elementId} not found`);
            return null; // or handle differently
        });
        return Promise.all(fetchPromises);
    }

    const constrainedQuery = query(elementCollectionRef, ...queryConstraints);
    const docSnapshot = await getDocs(constrainedQuery);

    if (docSnapshot.empty) {
        throw new Error(`Elements not found`);
    } else {
        return docSnapshot.docs.map(docLocal => ({ id: docLocal.id, ...docLocal.data() }));
    }
};

export const deleteElementsByIds = async (elementIds: string[], orgId: string, userId: string, elementType: string) => {
    const authLocal = getAuth();
    const user = authLocal.currentUser;
    if (!user || user.uid !== userId) {
        throw new Error("Unauthorized access");
    }

    const db = getFirestore();

    // Map each elementId to a promise that archives the document
    const archivePromises = elementIds.map(async (elementId) => {
        const elementRef = doc(db, 'organizations', orgId, elementType, elementId);
        const docSnapshot = await getDoc(elementRef);
        if (!docSnapshot.exists()) {
            throw new Error(`Element with ID ${elementId} not found`);
        }
        const element = docSnapshot.data();
        if (element?.userId !== userId) {
            throw new Error(`Element with ID ${elementId} not found`);
        }
        await setDoc(elementRef, { archived: true }, { merge: true });
    });

    // Await all archive promises concurrently
    await Promise.all(archivePromises);

    return true; // Indicate success
};

/** PUBLIC ELEMENTS */

export const fetchPublicElementsById = async (elementIds: string[], elementType: string) => {
    const db = getFirestore();

    if (elementIds && elementIds.length) {
        const fetchPromises = elementIds.map(async (elementId) => {
            const elementRef = doc(db, 'publicElements', elementType, elementId);
            const docSnapshot = await getDoc(elementRef);
            if (docSnapshot.exists()) {
                return { id: docSnapshot.id, ...docSnapshot.data() };
            }
            // Decide how to handle missing elements: throw error, return null, etc.
            console.error(`Element with ID ${elementId} not found`);
            return null; // or handle differently
        });
        return Promise.all(fetchPromises);
    }

    const elementCollectionRef = collection(db, 'publicElements', elementType);
    const docSnapshot = await getDocs(elementCollectionRef);

    if (docSnapshot.empty) {
        throw new Error(`Elements not found`);
    } else {
        return docSnapshot.docs.map(docLocal => ({ id: docLocal.id, ...docLocal.data() }));
    }
}

/** INVOICES */

export const getLatestInvoiceNumber = async (userId: string, orgId: string) => {
    const db = getFirestore();
    // collection from organization
    if (!userId || !orgId) {
        throw new Error("Invalid input parameters");
    }

    const invoicesCollectionRef = collection(db, 'organizations', orgId, 'invoices');
    const queryConstraints = [where('userId', '==', userId), where('archived', '==', false)];
    const q = query(invoicesCollectionRef, orderBy("invoiceNumber", "desc"), limit(1), ...queryConstraints);
    const querySnapshot = await getDocs(q);
    let latestInvoiceNumber = 'RE-0';
    querySnapshot.forEach((docLocal) => {
        latestInvoiceNumber = docLocal.data().invoiceNumber;
    });
    return latestInvoiceNumber;
};