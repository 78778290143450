import { paths } from 'src/routes/paths';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'Features',
    path: '/features',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    children: [
      { title: 'Features', path: '/features' },
      { title: 'Pricing', path: '/pricing' },
      { title: 'Contact', path: '/contact' },
      { title: 'Help Center', path: '/help-center' },
      { title: 'FAQs', path: '/faqs' },
    ],
  },
  {
    title: 'Organizations',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.organizations,
  },
  {
    title: 'Marketplace',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.marketplace,
    children: [
      { title: 'Events', path: '/events' },
      { title: 'Provider', path: '/provider' },
      { title: 'Meals', path: '/meal' },
      { title: 'Activities', path: '/activity' },
    ],
  },

];
