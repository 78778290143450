import { useEffect, forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TransitionProps } from '@mui/material/transitions';

import { useBoolean } from 'src/hooks/use-boolean';

import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

type Props = {
  onClose: () => void;
  openCustom: boolean;
};

export default function CustomTransitionsDialog({ onClose, openCustom }: Props) {
  const dialog = useBoolean(openCustom);

  const settings = useSettingsContext();

  useEffect(() => {
    if (openCustom) {
      dialog.onTrue();
    } else {
      dialog.onFalse();
    }

  }, [openCustom, dialog]);

  const handleClose = () => {
    onClose();
  }

  return (
    <div>
      {/* <Button variant="outlined" color="success" onClick={dialog.onTrue}>
        Bitte wähle eine Organisation oder persönlichen Workspace aus
      </Button> */}

      <Dialog
        keepMounted
        open={dialog.value && !settings.open}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogTitle>Workspace ausgewählt?</DialogTitle>

        <DialogContent sx={{ color: 'text.secondary' }}>
          Wahrscheinlich hast du noch keine Organisation oder Workspace ausgewählt. Dies passiert manchmal bei der ersten Anmeldung oder wenn Browserdaten gelöscht werden.
        </DialogContent>

        <DialogActions>

          <Button variant="contained" onClick={handleClose} autoFocus>
            Jetzt auswählen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
