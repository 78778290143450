import { m } from 'framer-motion';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Theme, SxProps } from '@mui/material/styles';
import Badge, { badgeClasses } from '@mui/material/Badge';

import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
import CustomTransitionsDialog from 'src/components/custom/transitions-dialog';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function SettingsButton({ sx }: Props) {
  const settings = useSettingsContext();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const {currentOrganization} = settings;
    const shouldBadgeBeVisible = !currentOrganization || currentOrganization === '' || currentOrganization === '0';
    setIsVisible(shouldBadgeBeVisible);
  }, [settings]);

  return (
    <>
      <Badge
        color="error"
        variant="dot"
        invisible={!isVisible}
        sx={{
          [`& .${badgeClasses.badge}`]: {
            top: 8,
            right: 8,
          },
          ...sx,
        }}
      >
        <Box
          component={m.div}
          animate={{
            rotate: [0, settings.open ? 0 : 360],
          }}
          transition={{
            duration: 12,
            ease: 'linear',
            repeat: Infinity,
          }}
        >
          <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            aria-label="settings"
            onClick={settings.onToggle}
            sx={{
              width: 40,
              height: 40,
            }}
          >
            <Iconify icon="solar:settings-bold-duotone" width={24} />
          </IconButton>
        </Box>
      </Badge>

      <CustomTransitionsDialog
        onClose={() => {
          settings.onToggle();
        }}
        openCustom={isVisible}
      />


    </>
  );
}
