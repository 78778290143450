import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { Button, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { fetchElementById } from 'src/utils/FireBaseUtils';

import { paper } from 'src/theme/css';
import { useAuthContext } from 'src/auth/hooks';

import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';

import { IAddressItem } from 'src/types/address';

import Iconify from '../../iconify';
import Scrollbar from '../../scrollbar';
import BaseOptions from './base-option';
import LayoutOptions from './layout-options';
import PresetsOptions from './presets-options';
import StretchOptions from './stretch-options';
import { useSettingsContext } from '../context';
import FullScreenOption from './fullscreen-option';

// ----------------------------------------------------------------------

export default function SettingsDrawer() {
  const theme = useTheme();

  const { user } = useAuthContext();

  const [organizations, setOrganizations] = useState<IAddressItem[]>([]);

  const popover = usePopover();


  const settings = useSettingsContext();


  useEffect(() => {
    const fetchOrganizations = async () => {
      try {

        const organizationsArray = Array.isArray(user?.organizations) ? user?.organizations : Object.values(user?.organizations || {});
        if (!organizationsArray) {
          enqueueSnackbar('No organizations found', { variant: 'error' });
          return;
        }
        const fetchedOrganizations = await fetchElementById(organizationsArray, settings.currentOrganization, user?.uid, 'organizations',);
        if (Array.isArray(fetchedOrganizations)) {
          setOrganizations(fetchedOrganizations as IAddressItem[]);
        } else {
          setOrganizations([fetchedOrganizations]);
        }
      } catch (error) {
        console.error(error);
      }

    }
    if (user?.organizations) {
      fetchOrganizations();
    }
  }, [user, settings.currentOrganization]);

  const labelStyles = {
    mb: 1.5,
    color: 'text.disabled',
    fontWeight: 'fontWeightSemiBold',
  };



  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Settings
      </Typography>

      {/* <Tooltip title="Reset">
        <IconButton onClick={settings.onReset}>
          <Badge color="error" variant="dot" invisible={!settings.canReset}>
            <Iconify icon="solar:restart-bold" />
          </Badge>
        </IconButton>
      </Tooltip> */}

      <IconButton onClick={settings.onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderOrganization = (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Organization
      </Typography>

      <Button
        color="inherit"
        variant="outlined"
        endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
        onClick={popover.onOpen}
        sx={{ textTransform: 'capitalize' }}
      >
        {organizations?.find((org) => org.id === settings.currentOrganization)?.company || 'Personal'}
      </Button>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="top-right"
        sx={{ maxWidth: 300, minWidth: 150 }}
      >

        <MenuItem
          key="personal"
          selected={settings.currentOrganization === user?.uid}
          onClick={
            () => {
              popover.onClose();
              settings.onUpdate('currentOrganization', user?.uid)
              settings.onUpdate('currentOrganizationName', user?.displayName || '');
              settings.onUpdate('currentOrganizationAddress', user?.address || '');
              settings.onUpdate('currentOrganizationCity', user?.city || '');
              settings.onUpdate('currentOrganizationCountry', user?.country || '');
              settings.onUpdate('currentOrganizationZip', user?.zipCode || '');
              settings.onUpdate('currentOrganizationPhone', user?.phoneNumber || '');
              settings.onUpdate('currentOrganizationEmail', user?.email || '');
              settings.onUpdate('currentOrganizationWebsite', user?.website || '');

            }
          }
          sx={{ fontStyle: 'italic', color: 'text.secondary' }}
        >
          Personal
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {organizations?.map((option) => (
          <MenuItem
            key={option.id}
            selected={option.id === settings.currentOrganization}
            onClick={() => {
              popover.onClose();
              settings.onUpdate('currentOrganization', option.id || '');
              settings.onUpdate('currentOrganizationName', option.company || '');
              settings.onUpdate('currentOrganizationAddress', option.address || '');
              settings.onUpdate('currentOrganizationCity', option.city || '');
              settings.onUpdate('currentOrganizationCountry', option.country || '');
              settings.onUpdate('currentOrganizationZip', option.zipCode || '');
              settings.onUpdate('currentOrganizationPhone', option.phoneNumber || '');
              settings.onUpdate('currentOrganizationEmail', option.email || '');
              settings.onUpdate('currentOrganizationWebsite', option.website || '');
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </CustomPopover>

    </div>
  );

  const renderMode = (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Mode
      </Typography>

      <BaseOptions
        value={settings.themeMode}
        onChange={(newValue: string) => settings.onUpdate('themeMode', newValue)}
        options={['light', 'dark']}
        icons={['sun', 'moon']}
      />
    </div>
  );

  const renderContrast = (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Contrast
      </Typography>

      <BaseOptions
        value={settings.themeContrast}
        onChange={(newValue: string) => settings.onUpdate('themeContrast', newValue)}
        options={['default', 'bold']}
        icons={['contrast', 'contrast_bold']}
      />
    </div>
  );

  /*   const renderDirection = (
      <div>
        <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
          Direction
        </Typography>
  
        <BaseOptions
          value={settings.themeDirection}
          onChange={(newValue: string) => settings.onUpdate('themeDirection', newValue)}
          options={['ltr', 'rtl']}
          icons={['align_left', 'align_right']}
        />
      </div>
    ); */

  const renderLayout = (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Layout
      </Typography>

      <LayoutOptions
        value={settings.themeLayout}
        onChange={(newValue: string) => settings.onUpdate('themeLayout', newValue)}
        options={['vertical', 'horizontal', 'mini']}
      />
    </div>
  );

  const renderStretch = (
    <div>
      <Typography
        variant="caption"
        component="div"
        sx={{
          ...labelStyles,
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        Stretch
        <Tooltip title="Only available at large resolutions > 1600px (xl)">
          <Iconify icon="eva:info-outline" width={16} sx={{ ml: 0.5 }} />
        </Tooltip>
      </Typography>

      <StretchOptions
        value={settings.themeStretch}
        onChange={() => settings.onUpdate('themeStretch', !settings.themeStretch)}
      />
    </div>
  );

  const renderPresets = (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Presets
      </Typography>

      <PresetsOptions
        value={settings.themeColorPresets}
        onChange={(newValue: string) => settings.onUpdate('themeColorPresets', newValue)}
      />
    </div>
  );

  return (
    <Drawer
      anchor="right"
      open={settings.open}
      onClose={settings.onClose}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 280,
        },
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          {renderOrganization}
          {renderMode}

          {renderContrast}

          {/* {renderDirection} */}

          {renderLayout}

          {renderStretch}

          {renderPresets}
        </Stack>
      </Scrollbar>

      <FullScreenOption />
    </Drawer>
  );
}
